<template>
  <div class="h-screen w-screen text-center flex items-stretch">
    <div class="w-full bg-blue h-2/4 flex-col">
      <div
        class="h-full flex justify-center items-center"
        :style="'background: ' + getColorTL()"
      >
        <div class="mx-auto">
          <input
            v-model="colorTL"
            placeholder="Enter color code..."
            class="px-1 py-2"
          />
        </div>
      </div>
      <div
        class="h-full flex justify-center items-center"
        :style="'background: ' + getColorTR()"
      >
        <div class="mx-auto">
          <input
            v-model="colorTR"
            placeholder="Enter color code..."
            class="px-1 py-2"
          />
        </div>
      </div>
    </div>
    <div class="w-full bg-blue h-2/4 flex-col">
      <!-- BOTTOM LEFT -->
      <div
        class="h-full flex justify-center items-center"
        :style="'background: ' + getColorBL()"
      >
        <div class="mx-auto">
          <input
            v-model="colorBL"
            placeholder="Enter color code..."
            class="px-1 py-2"
          />
        </div>
      </div>

      <!-- Bottom right -->
      <div
        class="h-full flex justify-center items-center"
        :style="'background: ' + ariannaColor()"
      >
        <div class="mx-auto">
          <input
            v-model="colorBR"
            placeholder="Enter color code..."
            class="px-1 py-2"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      colorTL: "",
      colorBL: "",
      colorTR: "",
      colorBR: "",
    };
  },
  methods: {
    getColorTL() {
      return this.colorTL;
    },
    getColorTR() {
      return this.colorTR;
    },
    getColorBL() {
      return this.colorBL;
    },
    ariannaColor() {
      return this.colorBR;
    },
  },
};
</script>

<style>
</style>