<template>
  <div class="flex">
    <div class="mainbody-l display hidden md:block bg-white">
      <slot name="mainbody-l"></slot>
    </div>
    <div class="mainbody-main container mx-auto max-w-screen-sm">
      <slot name="mainbody-main"></slot>
    </div>
    <div class="mainbody-r hidden md:block bg-white sticky">
      <slot name="mainbody-r"></slot>
    </div>
  </div>
</template> 

<script>
export default {
  props: {
    isfooter: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>