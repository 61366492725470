<template>
  <div class="relative">
    <!-- MAIN BODY -->
    <div class="mt-24 sm:mt-16">
      <div class="max-w-5xl mx-auto sm:px-6 grid grid-cols-12">
        <main class="col-span-12 md:col-span-8 lg:col-span-6">
          <!-- Your content -->
          <div v-if="stagePolls.length == 0" class="">
            <div class="flex justify-center mt-12">
              <loading />
            </div>
          </div>
          <div v-else class="pb-4">
            <poll-item
              v-for="pollId in stageIds.ids"
              :key="pollId"
              path="stage"
              @click="setPoll(pollId)"
              :pollId="pollId"
              pollStatus="stage"
              pollType="standard"
              :showPoll="true"
            />
          </div>
        </main>
        <div class="hidden md:block col-span-1 lg:col-span-3">
          <nav
            aria-label="Sidebar"
            class="sticky top-16 divide-y divide-gray-300"
          >
            <div class=""></div>
          </nav>
        </div>
        <div class="hidden md:block col-span-3">
          <nav
            aria-label="Sidebar"
            class="sticky top-16 divide-y divide-gray-300"
          >
            <div class="text-xs text-gray-500 pt-4 leading-4">
              Welcome to Oi Polly! Thanks for voting. We'd love to hear any
              feedback...
            </div>
          </nav>
        </div>
      </div>
    </div>
    <div class="z-100 shadow fixed top-0 h-16 w-screen bg-white">
      <!-- NON-MOBILE HEADER -->
      <div class="hidden sm:block max-w-5xl mx-auto">
        <div class="flex items-center h-16 justify-between">
          <div class="flex items-center">
            <img
              class="h-8 rounded-full mr-4 block sm:hidden cursor-pointer"
              src="@/assets/op_icon.png"
              alt=""
            />
            <img
              class="h-8 rounded-full mr-4 hidden sm:block cursor-pointer"
              src="@/assets/yopolly.png"
              alt=""
            />

            <!-- <div class="hidden sm:ml-6 sm:flex">
              <div class="relative">
                <btn
                  class="mt-1"
                  @toggleMenuPollType="toggleMenuPollType"
                  emitName="toggleMenuPollType"
                  buttonText="All Topics"
                  bold
                  icon="chevron-down"
                  color="theme"
                  large
                  :ripple="false"
                />
                <transition>
                  <dropdown-menu
                    v-if="selectTopicMenu"
                    class="absolute right-4 top-14 bg-white shadow w-24"
                  >
                    <div class="text-sm">cat 1</div>
                    <div class="">cat 2</div>
                    <div class="">cat 3</div>
                  </dropdown-menu>
                </transition>
              </div>
              <btn
                class="mt-1"
                @toggleMenuPollType="toggleMenuPollType"
                emitName="toggleMenuPollType"
                buttonText="Latest"
                bold
                icon="chevron-down"
                color="theme"
                large
                :ripple="false"
              />
            </div> -->
          </div>
        </div>
      </div>
      <!-- MOBILE HEADER -->
      <div class="block sm:hidden shadow">
        <div class="bg-white border-gray h-12 flex items-center">
          <img
            class="h-10 w-10 rounded-full mr-4 visible sm:invisible"
            src="@/assets/op_icon.png"
            alt=""
          />
          <img
            class="h-10 w-10 rounded-full mr-4 invisible sm:visible"
            src="@/assets/yopolly.png"
            alt=""
          />
        </div>
        <!-- <div class="bg-white h-12">
          <div class="flex justify-between">
            <btn
              class="mt-1"
              @toggleMenuPollType="toggleMenuPollType"
              emitName="toggleMenuPollType"
              buttonText="All Topics"
              bold
              icon="chevron-down"
              color="theme"
              large
              :ripple="false"
              iconSize="xsmall"
            />
            <btn
              class="mt-1"
              @toggleMenuPollType="toggleMenuPollType"
              emitName="toggleMenuPollType"
              buttonText="Latest"
              bold
              icon="chevron-down"
              color="theme"
              large
              :ripple="false"
            />
          </div>
        </div> -->
      </div>
    </div>
    <modal
      v-if="state.showModal"
      @click="closeModal"
      :poll="state.selectedPoll"
    />
  </div>
</template>

<script>
import { db, uid, stagePolls, fetchStage } from "../db/firebase";
import { getStageIds, stageIds } from "../db/feed";
import { doc, onSnapshot } from "firebase/firestore";
import { reactive, onMounted, ref, watch } from "vue";
// import Btn from "../components/ui/buttons/btn.vue";
// import CustomHeader from "../components/layout/custom-header.vue";
// import MainBody from "../components/layout/main-body.vue";
// import HeaderElement from "../components/layout/header-element.vue";
import PollItem from "../components/poll/poll-item/poll-item.vue";
import Modal from "../components/layout/modal.vue";
import Loading from "../components/ui/loading.vue";
// import DropdownMenu from "../components/ui/dropdown/dropdown-menu.vue";
import { useRoute, useRouter } from "vue-router";
// import startOfYesterday from "date-fns/startOfYesterday/index.js";
export default {
  components: {
    Loading,
    PollItem,
    Modal,
  },

  setup() {
    const selectTopicMenu = ref(false);

    const state = reactive({
      selectedPoll: null,
      showModal: false,
      polls: [],
    });
    const route = useRoute();
    const router = useRouter();

    const setPoll = (pollId) => {
      onSnapshot(doc(db, "polls-stage", pollId), (doc) => {
        state.selectedPoll = doc.data();
        console.log(doc.data());
      });
    };

    const closeModal = () => {
      // state.showModal = false;
      router.push("/stage");
      state.selectedPoll = null;
    };

    watch(
      () => route.name,
      () => {
        // setPoll();
        state.showModal = route.name == "StageDetail";
      }
    );

    const getFeed = () => {
      // console.log("getting feed");
      getStageIds();
    };

    onMounted(async () => {
      getFeed();
      fetchStage();
      console.log("fetch stage polls");
    });

    return {
      state,
      setPoll,
      closeModal,
      uid,
      selectTopicMenu,
      stagePolls,
      stageIds,
    };
  },
};
</script>

<style>
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>